import { ListContextProvider, SimpleList, useList } from "react-admin";
import { Avatar, TextField as MuiTextField } from "@mui/material";
import { useEffect, useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import { useDatabase } from "@nozbe/watermelondb/hooks";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Q } from "@nozbe/watermelondb";
import debounce from "lodash/debounce";
import { Product } from "../../model/Product";

function CustomFilter({
  value,
  setValue,
}: {
  value: string | null | undefined;
  setValue: (value: string | null) => void;
}) {
  const [localValue, setLocalValue] = useState("");

  const propagate = debounce((value: string | null) => {
    setValue(value);
  }, 500);

  const handleChange = (value: string) => {
    setLocalValue(value);
    propagate(value);
  };

  return (
    <MuiTextField
      value={localValue || ""}
      placeholder="Search"
      InputProps={{
        endAdornment: value ? (
          <CloseIcon onClick={() => setValue(null)} />
        ) : (
          <SearchIcon />
        ),
      }}
      onChange={(ev) => handleChange(ev.target.value)}
    />
  );
}

interface IProduct {
  id: string;
  createdAt: number;
  updatedAt?: number;
  description: string;
  um?: string;
  cost?: string;
}

export function ProductsListMobile() {
  const [products, setProducts] = useState<IProduct[]>([]);
  const database = useDatabase();
  const [filter, setFilter] = useState<string | null>();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (filter) {
      database
        .get<Product>("products")
        .query(
          Q.or(
            Q.where("id", Q.like(`%${Q.sanitizeLikeString(filter || "")}%`)),
            Q.where(
              "description",
              Q.like(`%${Q.sanitizeLikeString(filter || "")}%`)
            )
          ),
          Q.take(10)
        )
        .then((products) => {
          setProducts(
            products.map((product) => ({
              id: product.id,
              createdAt: product.createdAt,
              updatedAt: product.updatedAt,
              description: product.description,
              um: product.um,
              cost: product.cost,
            }))
          );
        });
    } else {
      database
        .get<Product>("products")
        .query(Q.take(10))
        .then((products) => {
          setProducts(
            products.map((product) => ({
              id: product.id,
              createdAt: product.createdAt,
              updatedAt: product.updatedAt,
              description: product.description,
              um: product.um,
              cost: product.cost,
            }))
          );
        });
    }
    database
      .get<Product>("products")
      .query()
      .fetchCount()
      .then((total) => {
        setTotal(total);
      });
    setTotal(10);
  }, [database, filter]);

  const context = useList({
    resource: "products",
    data: products,
  });

  return (
    <ListContextProvider value={context}>
      <p>{total}</p>
      <CustomFilter value={filter} setValue={setFilter} />
      <SimpleList
        linkType="show"
        leftAvatar={() => (
          <Avatar sx={{ bgcolor: "primary.main" }}>
            <CategoryIcon />
          </Avatar>
        )}
        primaryText={(record) => record.description}
        secondaryText={(record) => record.id}
      />
    </ListContextProvider>
  );
}
