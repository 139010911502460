import { appSchema, tableSchema } from "@nozbe/watermelondb";

export const dbSchema = appSchema({
  version: 1,
  tables: [
    tableSchema({
      name: "products",
      columns: [
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
        { name: "description", type: "string", isIndexed: true },
        { name: "um", type: "string", isOptional: true },
        { name: "cost", type: "string", isOptional: true },
      ],
    }),
  ],
});
