import { Model } from "@nozbe/watermelondb";
import { readonly, date, text } from "@nozbe/watermelondb/decorators";

export class Product extends Model {
  static table = "products";

  @readonly @date("created_at") createdAt!: number;
  @readonly @date("updated_at") updatedAt!: number;

  @text("description") description!: string;
  @text("um") um?: string;
  @text("cost") cost?: string;
}
