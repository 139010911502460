// in src/MyMenu.js
import { Menu } from "react-admin";
import { useMediaQuery, Theme, Collapse, Box } from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import CategoryIcon from "@mui/icons-material/Category";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import WarehouseIcon from "@mui/icons-material/Storefront";
import LabelIcon from "@mui/icons-material/Label";
import SettingsIcon from "@mui/icons-material/Settings";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PersonIcon from "@mui/icons-material/Person";
import { ReactElement, useState } from "react";

export function MenuSmall() {
  return (
    <Menu>
      <Menu.DashboardItem />
      <Menu.Item
        to="/inventories"
        primaryText="Inventories"
        leftIcon={<InventoryIcon />}
      />
      <Menu.Item
        to="/products"
        primaryText="Products"
        leftIcon={<CategoryIcon />}
      />
    </Menu>
  );
}

interface MenuFolderProps {
  initialOpen?: boolean;
  children: ReactElement | ReactElement[];
  label: string;
  icon?: ReactElement;
}

export function MenuFolder({
  initialOpen = false,
  children,
  label,
  icon,
}: MenuFolderProps) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <>
      <Menu.Item
        to="#"
        onClick={() => setIsOpen(!isOpen)}
        primaryText={label}
        leftIcon={icon}
      />
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box paddingLeft="10px">{children}</Box>
      </Collapse>
    </>
  );
}

export function MyMenu() {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    return <MenuSmall />;
  }

  return (
    <Menu>
      <Menu.DashboardItem />
      <Menu.Item
        to="/inventories"
        primaryText="Inventories"
        leftIcon={<InventoryIcon />}
      />
      <Menu.Item
        to="/products"
        primaryText="Products"
        leftIcon={<CategoryIcon />}
      />
      <MenuFolder label="Import-Export" icon={<ImportExportIcon />}>
        <Menu.Item
          to="/imports"
          primaryText="Import products"
          leftIcon={<DownloadIcon />}
        />
        <Menu.Item
          to="/inventories_export"
          primaryText="Export inventories"
          leftIcon={<UploadIcon />}
        />
      </MenuFolder>
      <MenuFolder label="Configuration" icon={<SettingsIcon />}>
        <Menu.Item
          to="/warehouses"
          primaryText="Warehouses"
          leftIcon={<WarehouseIcon />}
        />
        <Menu.Item to="/labels" primaryText="Labels" leftIcon={<LabelIcon />} />
        <Menu.Item
          to="/profiles_full"
          primaryText="Users"
          leftIcon={<PersonIcon />}
        />
      </MenuFolder>
    </Menu>
  );
}
