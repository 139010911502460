import {
  Show,
  SimpleShowLayout,
  TextField,
  useNotify,
  useRecordContext,
  useTranslate,
} from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { supabaseClient } from "../../providers/supabase";

function ResetButton() {
  const notify = useNotify();
  const record = useRecordContext();
  const [loading, setLoading] = useState(false);
  const t = useTranslate();

  async function sendResetPassword() {
    setLoading(true);
    const { error } = await supabaseClient.auth.resetPasswordForEmail(
      record.email
    );
    setLoading(false);

    if (error) {
      notify("worklinks.errors.cannot_send_reset_password_email", {
        type: "error",
      });
    } else {
      notify("worklinks.messages.password_reset_email_sent", {
        type: "success",
      });
    }
  }

  return (
    <LoadingButton onClick={sendResetPassword} loading={loading}>
      {t("worklinks.buttons.reset_password")}
    </LoadingButton>
  );
}

export function UsersShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="email" />
        <ResetButton />
      </SimpleShowLayout>
    </Show>
  );
}
