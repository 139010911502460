import { Box } from "@mui/material";
import import_csv from "../../assets/icons/import_csv.png";

export function Placeholder() {
  return (
    <>
      <b>Trascina il file da caricare, oppure clicca per selezionarlo.</b>
      <p>Puoi caricare i seguenti formati:</p>
      <Box>
        <img src={import_csv} alt="upload csv" style={{ opacity: 0.5 }} />
      </Box>
    </>
  );
}
