import { Edit, ReferenceInput, SelectInput, SimpleForm } from "react-admin";

export const NotificationEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="sender" reference="profiles_full">
        <SelectInput optionText="first_name" />
      </ReferenceInput>
      <ReferenceInput source="receiver" reference="profiles_full">
        <SelectInput optionText="first_name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
