import {
  FilterList,
  FilterListItem,
  Loading,
  RaRecord,
  SavedQueriesList,
  useGetList,
} from "react-admin";
import { Card, CardContent } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { IProfile } from "../../../types/derived_types";

export const InventoriesFilterSidebar = () => {
  const { isLoading, error, data } = useGetList<IProfile>("profiles_full");

  const getUserLabel = (record: RaRecord): string => {
    return record.first_name !== null && record.last_name !== null
      ? `${record.first_name} ${record.last_name}`
      : `${record.email}`;
  };

  return (
    <Card sx={{ order: -1, mr: 2, mt: 0, width: 300 }}>
      <CardContent>
        <SavedQueriesList />
        <FilterList label="Status" icon={<MailIcon />}>
          <FilterListItem
            label="Completed"
            value={{
              "completion_date@not.is": "null",
              "completion_date@is": undefined,
            }}
          />
          <FilterListItem
            label="Not completed"
            value={{
              "completion_date@not.is": undefined,
              "completion_date@is": "null",
            }}
          />
        </FilterList>
        <FilterList label="Assigned to" icon={<PersonAddIcon />}>
          {isLoading ? (
            <Loading />
          ) : error ? (
            <p>ERROR</p>
          ) : (
            data &&
            data.map((record) => (
              <FilterListItem
                key={record.id}
                label={getUserLabel(record)}
                value={{ assigned_to: record.id }}
              />
            ))
          )}
        </FilterList>
        <FilterList label="Created by" icon={<PersonIcon />}>
          {isLoading ? (
            <Loading />
          ) : error ? (
            <p>ERROR</p>
          ) : (
            data &&
            data.map((record) => (
              <FilterListItem
                key={record.id}
                label={getUserLabel(record)}
                value={{ created_by: record.id }}
              />
            ))
          )}
        </FilterList>
      </CardContent>
    </Card>
  );
};
