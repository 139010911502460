import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export function InventoriesCreate() {
  return (
    <Create>
      <SimpleForm sx={{ maxWidth: "500px" }}>
        <TextInput source="name" fullWidth required />
        <TextInput source="description" fullWidth multiline minRows={2} />
        <ReferenceInput source="assigned_to" reference="profiles_full">
          <SelectInput optionText="first_name" fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
