import { synchronize } from "@nozbe/watermelondb/sync";
import { IconButton, Theme, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { dataProvider } from "../providers/DataProvider";

import { database } from "../model/database";

const firstDate = new Date(2000, 1, 1);

async function mySync() {
  await synchronize({
    database,
    pullChanges: async ({ lastPulledAt }) => {
      const lastSyncDate = new Date(lastPulledAt || firstDate);
      const { data } = await dataProvider.getList("products", {
        pagination: { page: 1, perPage: 100000 },
        filter: {
          "created_at@gt": lastSyncDate.toISOString(),
        },
        sort: { field: "updated_at", order: "DESC" },
      });
      const changes = {
        products: {
          created: data.map((product: any) => ({
            id: product.id,
            createdAt: product.created_at,
            updatedAt: product.updated_at,
            description: product.description,
            um: product.um,
            cost: product.cost,
          })),
          updated: [],
          deleted: [],
        },
      };

      return { changes, timestamp: new Date().getTime() };
    },
  });
}

export function DatabaseSync() {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [inSync, setInSync] = useState(false);

  // We want to sync the local database only on small devices
  if (!isSmall) {
    return null;
  }

  async function syncDatabase() {
    setInSync(true);
    await mySync();
    setInSync(false);
  }
  return (
    <IconButton onClick={syncDatabase} disabled={inSync}>
      {!inSync ? <RefreshIcon /> : <CircularProgress />}
    </IconButton>
  );
}
