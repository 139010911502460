import { CreateResult, useDataProvider, useNotify } from "react-admin";
import { FileDragAndDrop, pageType } from "./FileDragAndDrop";

export function InventoryImportsList() {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const importRow = (
    currentPage: pageType,
    mappedCSV: any | null,
    parsedCSV: any[] | null
  ) => {
    switch (currentPage) {
      case pageType.INVENTORIES:
        {
          const temp: any = { ...mappedCSV };
          let reqPayload: any = {};

          if (parsedCSV !== null && temp !== null && parsedCSV.length > 0) {
            Object.entries(temp).forEach((value: [string, any]) => {
              if (
                Object.prototype.hasOwnProperty.call(parsedCSV[0], value[1]) &&
                (value[0] === "name" ||
                  value[0] === "description" ||
                  value[0] === "assigned_to" ||
                  value[0] === "expire_date")
              ) {
                reqPayload[`${value[0]}`] = parsedCSV[0][`${value[1]}`];
              }
            });

            dataProvider
              .create("inventories", { data: reqPayload })
              .then((value: CreateResult<any>) => {
                reqPayload = {};

                for (const data of parsedCSV) {
                  delete data["name"];
                  delete data["description"];
                  delete data["assigned_to"];
                  delete data["expire_date"];

                  // eslint-disable-next-line no-loop-func
                  Object.entries(temp).forEach((value: [string, any]) => {
                    if (Object.prototype.hasOwnProperty.call(data, value[1])) {
                      reqPayload[value[0]] = data[value[1]];
                    }
                  });

                  reqPayload["inventory_id"] = value.data.id;

                  dataProvider
                    .create("inventory_rows", { data: reqPayload })
                    .then(() => {
                      notify("Inventari importati con successo", {
                        type: "success",
                      });
                    })
                    .catch((reason: any) => {
                      notify(`${reason}`, { type: "error" });
                    });
                }
              })
              .catch((reason: any) => {
                notify(`${reason}`, { type: "error" });
              });
          }
        }

        break;
      default:
        console.error("WRONG PAGE TYPE!");
        break;
    }
  };

  const csvColumnToMap: { name: string }[] = [
    { name: "name" },
    { name: "description" },
    { name: "assigned_to" },
    { name: "expire_date" },
    { name: "quantity" },
    { name: "code" },
    { name: "remote_quantity" },
  ];

  return (
    <FileDragAndDrop
      csvColumnToMap={csvColumnToMap}
      currentPage={pageType.INVENTORIES}
      importRow={importRow}
    />
  );
}
