import {
  DateField,
  ReferenceField,
  ShowContextProvider,
  SimpleShowLayout,
  TextField,
  useShowController,
} from "react-admin";
import { Button } from "@mui/material";

function FetchingError({ refetch }: { refetch: () => void }) {
  return (
    <>
      <p>Error while fetching data</p>
      <p>It looks like you have no internet connection</p>
      <Button onClick={() => refetch()} variant="contained">
        Try to refetch
      </Button>
    </>
  );
}

export function InventoriesExportShow() {
  const context = useShowController({
    queryOptions: {
      onError: (error) => {
        console.error(error);
      },
    },
  });
  const { error, refetch } = context;
  if (error) {
    return <FetchingError refetch={refetch} />;
  }

  return (
    <ShowContextProvider value={context}>
      <SimpleShowLayout>
        <DateField label="Creato il" source="created_at" showTime />
        <TextField label="Id" source="inventories_id" />
        <ReferenceField
          label="Nome"
          source="inventories_id"
          reference="inventories"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Descrizione"
          source="inventories_id"
          reference="inventories"
          link="show"
        >
          <TextField source="description" />
        </ReferenceField>
        <ReferenceField
          label="Data fine"
          source="inventories_id"
          reference="inventories"
          link="show"
        >
          <TextField source="expire_date" />
        </ReferenceField>
      </SimpleShowLayout>
    </ShowContextProvider>
  );
}
