import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { FieldValues } from "react-hook-form";
import Papa from "papaparse";
import { UUID } from "crypto";
import { supabaseClient } from "../../providers/supabase";
import { downloadCSV } from "../../utils/DownloadCSV";

export interface IFullInventories {
  assigned_to: UUID | null;
  code: string | null;
  description: string | null;
  expire_date: string;
  id: string;
  name: string | null;
  quantity: number | null;
  remote_quantity: number | null;
}

export function InventoriesExportCreate() {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  function onSubmit(formValues: FieldValues) {
    const { inventories_id: id } = formValues;
    dataProvider
      .create("inventories_export", { data: { inventories_id: id } })
      .then(() => {
        callFunction(id).then((value: IFullInventories[]) => {
          if (value.length > 0) {
            const data = value;
            const headerFields: string[] = Object.keys(value[0]).filter(
              (k: string) => k !== "id"
            );
            downloadCSV(Papa.unparse({ data, fields: headerFields }));


            notify("Esportazione effettuata con successo", { type: "info" });
            redirect("/inventories_export");
          }
        });
      })
      .catch((reason: any) => {
        notify(`${reason}`, { type: "error" });
      });
  }

  async function callFunction(invId: string): Promise<IFullInventories[]> {
    const { data, error } = await supabaseClient.rpc("get_inventory_by_id", {
      _inventory_id_: invId,
    });
    const result: IFullInventories[] = [];

    if (error) {
      notify(`${error}`, { type: "error" });
    } else {
      for (const d of data as any) {
        result.push({
          assigned_to: d.assigned_to,
          code: d.code,
          description: d.description,
          expire_date: d.expire_date,
          id: d.id,
          name: d.name,
          quantity: d.quantity,
          remote_quantity: d.remote_quantity,
        });
      }
    }

    return result;
  }

  return (
    <Create>
      <SimpleForm onSubmit={onSubmit}>
        <ReferenceInput
          label="Nome"
          source="inventories_id"
          reference="inventories"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
