import {
  Datagrid,
  DateField,
  FilterLiveSearch,
  List,
  SimpleList,
  TextField,
  ChipField,
  useRecordContext,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

export function WarehouseListMobile() {
  return (
    <List>
      <SimpleList primaryText={(record) => record.id} />
    </List>
  );
}

export const CustomChipField = () => {
  const record = useRecordContext();
  return record && record.color ? (
    <ChipField
      label="Colore"
      source="color"
      sx={{ backgroundColor: record.color }}
    />
  ) : null;
};

export function WarehouseList() {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    return <WarehouseListMobile />;
  }

  const filters = [<FilterLiveSearch key={1} source="name@ilike" alwaysOn />];

  return (
    <List filters={filters}>
      <Datagrid rowClick="edit">
        <TextField label="Id" source="id" />
        <TextField label="Nome" source="name" />
        <DateField label="Creato il" source="created_at" showTime />
        <CustomChipField />
      </Datagrid>
    </List>
  );
}
