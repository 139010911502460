import {
  Datagrid,
  DateField,
  FilterLiveSearch,
  List,
  RaRecord,
  SimpleList,
  TextField,
} from "react-admin";
import { InfiniteList, ReferenceField } from "ra-ui-materialui";
import { useMediaQuery, Theme, Avatar } from "@mui/material";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import RuleIcon from "@mui/icons-material/Rule";

export function InventoriesExportListMobile() {
  const smallFilters = [
    <FilterLiveSearch
      key={1}
      source="inventories_id@ilike"
      alwaysOn
      fullWidth
    />,
  ];

  const getAvatarBackgroundColor = (record: RaRecord) => {
    if (record.completion_date) {
      return "green";
    }
    if (record.expire_date < new Date().toISOString()) {
      return "red";
    }
    return "primary.main";
  };

  function getAvatarIcon(record: RaRecord) {
    if (!record.completion_date) {
      return <RuleIcon />;
    }
    return <ChecklistRtlIcon />;
  }

  return (
    <InfiniteList
      resource="inventories_export"
      exporter={false}
      filters={smallFilters}
      actions={false}
      sx={{
        "& form": { width: "100%" },
        "& .RaFilterForm-filterFormInput": { width: "100%" },
      }}
    >
      <SimpleList
        leftAvatar={(record) => (
          <Avatar sx={{ bgcolor: getAvatarBackgroundColor(record) }}>
            {getAvatarIcon(record)}
          </Avatar>
        )}
        primaryText={(record) => record.name}
        secondaryText={(record) =>
          `Due: ${new Date(record.expire_date).toLocaleString()}`
        }
      />
    </InfiniteList>
  );
}

export function InventoriesExportList() {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    return <InventoriesExportListMobile />;
  }

  const filters = [
    <FilterLiveSearch key={1} source="inventories_id@ilike" alwaysOn />,
  ];

  return (
    <List filters={filters}>
      <Datagrid rowClick="show">
        <DateField label="Creato il" source="created_at" showTime />
        <TextField label="Id" source="inventories_id" />
        <ReferenceField
          label="Nome"
          source="inventories_id"
          reference="inventories"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Descrizione"
          source="inventories_id"
          reference="inventories"
          link="show"
        >
          <TextField source="description" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
