import { DateInput, Edit, SimpleForm, TextInput } from "react-admin";
import { ColorInput } from "react-admin-color-picker";

export function WarehouseEdit() {
  return (
    <Edit>
      <SimpleForm>
        <TextInput label="Id" source="id" />
        <TextInput label="Nome" source="name" />
        <DateInput label="Creato il" source="created_at" />
        <ColorInput label="Colore" source="color" />
      </SimpleForm>
    </Edit>
  );
}
