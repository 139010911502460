import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ListBase, useGetIdentity } from "ra-core";
import { Datagrid, NumberField, TextField } from "react-admin";

export function UserWelcome() {
  const { isLoading, error, data } = useGetIdentity();
  if (isLoading) {
    return <Typography variant="h1">✋ Welcome </Typography>;
  }
  if (error) {
    console.error(error);
    return null;
  }
  return <Typography variant="h1">✋ Welcome {data.fullName}</Typography>;
}

export function Dashboard() {
  return (
    <Box>
      <UserWelcome />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Inventari da completare" />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Inventari scaduti" />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Inventari completati" />
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Inventari per utente" />
            <ListBase
              resource="inventories_per_user"
              disableSyncWithLocation={true}
            >
              <Datagrid bulkActionButtons={false}>
                <TextField source="full_name" />
                <NumberField source="to_complete" />
                <NumberField source="completed" />
                <NumberField source="expired" />
              </Datagrid>
            </ListBase>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Prodotti con maggior numero di rettifiche" />
            <ListBase
              resource="product_quantities"
              disableSyncWithLocation={true}
            >
              <Datagrid bulkActionButtons={false}>
                <TextField source="code" />
                <TextField source="description" />
                <NumberField source="total" label="Numero di rettifiche" />
              </Datagrid>
            </ListBase>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
