import {
  MenuItem,
  Select,
  styled,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import papa from "papaparse";
import { useState } from "react";
import { FileInput, Form, Loading, useNotify } from "react-admin";
import { FileRejection } from "react-dropzone";
import { Placeholder } from "./Placeholder";

export enum pageType {
  INVENTORIES,
  PRODUCTS,
}

const StyledFileInput = styled(FileInput)({
  border: "3px dashed #ababab",
  width: "100%",
  borderRadius: "0.5rem",
  "& .RaFileInput-dropZone": {
    bgcolor: "transparent",
  },
  "& .RaLabeled-label": {
    display: "none",
  },
  ":hover": {
    border: "3px dashed primary.main",
  },
});

const FILE_MAX_SIZE = 100000000; // 100 Mb

export interface FileDragAndDropProps {
  csvColumnToMap: any[];
  currentPage: pageType;
  importRow: (
    currentPage: pageType,
    mappedCSV: any | null,
    parsedCSV: any[] | null
  ) => void;
  fileMaxSize?: number;
}

// TODO refactor this file to moce the parse function out of the components
// and create a pass down only the callbacks to be called (onError, onComplete)
export function FileDragAndDrop({
  csvColumnToMap,
  currentPage,
  importRow,
  fileMaxSize = FILE_MAX_SIZE,
}: FileDragAndDropProps) {
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [parsedCSV, setParsedCSV] = useState<any[] | null>(null);
  const [mappedCSV, setMappedCSV] = useState<any | null>(null);
  const [columnsParsedCSV, setColumnsParsedCSV] = useState<
    string[] | undefined
  >(undefined);

  const handleChange = (key: string, event: SelectChangeEvent) => {
    const c = mappedCSV;
    c[`${key}`] = `${event.target.value}`;
    setMappedCSV(c);
  };

  function parseCsv(file: File): void {
    if (!file) {
      return;
    }

    setIsLoading(true);

    const papaConfigs = {
      header: true,
      worker: true,
      dynamicTyping: true,
      error() {
        setParsedCSV(null);
        setColumnsParsedCSV(undefined);
        setMappedCSV(null);
        setIsLoading(false);
        notify("Error when parsing CSV", { type: "error" });
      },
      complete(result: papa.ParseResult<any>) {
        setParsedCSV(result.data);
        setColumnsParsedCSV(result.meta.fields);
        setIsLoading(false);
        setMappedCSV({});
      },
    };

    papa.parse<any, File>(file, papaConfigs);
  }

  function onDrop(_: File[], fileRejected: FileRejection[]): void {
    if (fileRejected.length > 0) {
      if (fileRejected[0].errors[0].code === "file-too-large") {
        notify(
          "Error: the file is too big. The maximum supported size is 100 MB",
          { type: "error" }
        );
        setParsedCSV(null);
        setColumnsParsedCSV(undefined);
        setMappedCSV(null);
        return;
      }
      notify("Error loading file: " + fileRejected[0].errors[0].message, {
        type: "error",
      });
      setParsedCSV(null);
      setColumnsParsedCSV(undefined);
      setMappedCSV(null);
    }
  }

  function onChange(file: File): void {
    if (!file) {
      return;
    }
    switch (file.type) {
      case "text/csv":
        parseCsv(file);
        break;
      default:
        notify("Format not supported", { type: "error" });
        break;
    }
  }

  return (
    <div>
      {parsedCSV !== null && columnsParsedCSV !== undefined ? (
        <>
          {csvColumnToMap.map((column: any) => (
            <p key={column.name}>
              {column.name}{" "}
              <span>
                <Select
                  onChange={(event: SelectChangeEvent) =>
                    handleChange(column.name, event)
                  }
                >
                  {columnsParsedCSV.map((parsedColumn: string) => (
                    <MenuItem value={parsedColumn} key={parsedColumn}>
                      {parsedColumn}
                    </MenuItem>
                  ))}
                </Select>
              </span>
            </p>
          ))}
          <Button
            variant="text"
            onClick={() => importRow(currentPage, mappedCSV, parsedCSV)}
          >
            Importa le righe
          </Button>
        </>
      ) : (
        <Form>
          <StyledFileInput
            source={"attachments"}
            accept={"text/csv"}
            multiple={false}
            maxSize={fileMaxSize}
            options={{ onDrop }}
            placeholder={
              isLoading ? (
                <Loading sx={{ maxHeight: "250px" }} />
              ) : (
                <Placeholder />
              )
            }
            onChange={onChange}
          />
        </Form>
      )}
    </div>
  );
}
