import { TranslationMessages } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import ra_en from "ra-language-english";
// @ts-ignore
import ra_it from "ra-language-italian";
import domain_en from "./en.json";
import domain_it from "./it.json";

export const translations: Record<string, TranslationMessages> = {
  en: {
    ...ra_en,
    ...domain_en,
  },
  it: {
    ...ra_it,
    ...domain_it,
  },
};

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  "en", // default locale
  [
    { locale: "en", name: "English" },
    { locale: "it", name: "Italian" },
  ],
  {
    allowMissing: true,
  }
);
