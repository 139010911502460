import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Theme,
  Button,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useResetPassword } from "ra-supabase";
import { ReactNode } from "react";
import { Form, TextInput, required, useNotify } from "react-admin";
import { FieldValues } from "react-hook-form";

const ResponsiveBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ECECEC",
  padding: "5rem",
  [theme.breakpoints.only("xs")]: {
    width: "100%",
    height: "950px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "950px",
  },
  [theme.breakpoints.only("sm")]: {
    width: "100%",
    height: "950px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: "100%",
    height: "1200px",
  },
  [theme.breakpoints.only("md")]: {
    width: "100%",
    height: "1200px",
  },
  [theme.breakpoints.only("lg")]: {
    width: "100%",
    height: "1200px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
    height: "1200px",
  },
}));
const GridItem = styled(Card)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
}));

export interface IForgotPasswordPageProps {
  children?: ReactNode;
}

/**
 * A component that renders a form for resetting the user password.
 */
export const ForgotPasswordForm = () => {
  const notify = useNotify();
  const isPageSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const [resetPassword] = useResetPassword({
    onError: (error) => {
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message,
        {
          type: "warning",
          messageArgs: {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        }
      );
    },
    onSuccess: () => {
      notify(
        "E' stata inviata una email per procedere con il reset della password",
        { type: "info" }
      );
    },
  });

  const submit = (values: FieldValues) => {
    return resetPassword({ email: values.email });
  };

  return (
    <Form onSubmit={submit}>
      <div>
        {isPageSmall === true ? (
          <TextInput
            variant="standard"
            source="email"
            label="Email"
            autoComplete="email"
            fullWidth
            validate={required()}
          />
        ) : (
          <TextInput
            variant="standard"
            source="email"
            label="Email"
            autoComplete="email"
            validate={required()}
          />
        )}
      </div>
      <div>
        {isPageSmall === true ? (
          <Button variant="contained" type="submit" fullWidth>
            Reset password
          </Button>
        ) : (
          <Button variant="contained" type="submit">
            Reset password
          </Button>
        )}
      </div>
    </Form>
  );
};

export const ForgotPasswordPage = (props: IForgotPasswordPageProps) => {
  const { children = <ForgotPasswordForm /> } = props;
  const isPageSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const leftGridItemStyle = (isPageSmall: boolean): any => {
    return isPageSmall === true
      ? { height: "100%", borderRadius: "2rem" }
      : {
          height: "100%",
          borderTopLeftRadius: "2rem",
          borderBottomLeftRadius: "2rem",
          backgroundColor: "#ffffff",
        };
  };

  return isPageSmall === true ? (
    <Box sx={{ padding: "2rem" }}>
      <CardHeader
        avatar={
          <Avatar
            alt="company logo"
            src="/icon-512x512.png"
            sx={{ width: 50, height: 50 }}
          />
        }
        title={
          <Typography
            sx={{ fontSize: 18, fontWeight: 600, textAlign: "center" }}
            color="text.primary"
            gutterBottom
          >
            EasyInventory
          </Typography>
        }
      ></CardHeader>
      <Typography
        sx={{ fontSize: 18, fontWeight: 600, textAlign: "center" }}
        color="text.primary"
        gutterBottom
      >
        Reset password
      </Typography>
      {children}
      <br />
    </Box>
  ) : (
    <ResponsiveBox>
      <Grid
        sx={{ height: "100%", borderRadius: "2rem" }}
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{ height: "100%" }}>
          <GridItem sx={{ ...leftGridItemStyle(isPageSmall) }}>
            <CardHeader
              avatar={
                <Avatar
                  alt="company logo"
                  src="/icon-512x512.png"
                  sx={{ width: 50, height: 50 }}
                />
              }
              title={
                <Typography
                  sx={{ fontSize: 18, fontWeight: 600 }}
                  color="text.primary"
                  gutterBottom
                >
                  EasyInventory
                </Typography>
              }
            ></CardHeader>
            <CardContent sx={{ textAlign: "center" }}>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.primary"
                gutterBottom
              >
                Reset password
              </Typography>
              {children}
              <br />
            </CardContent>
          </GridItem>
        </Grid>
        <Grid item md={5} lg={5} xl={5} sx={{ height: "100%" }}>
          <CardMedia
            sx={{
              borderTopRightRadius: "2rem",
              borderBottomRightRadius: "2rem",
            }}
            component="img"
            height="100%"
            image="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/f64ff684-b2f8-4c08-ac14-297dd9013c3c/df2n2hn-a2a0c684-3131-4dee-a553-5a5b081ba5ff.png/v1/fill/w_1280,h_800,q_80,strp/google_colors_by_jesssaysno_df2n2hn-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9ODAwIiwicGF0aCI6IlwvZlwvZjY0ZmY2ODQtYjJmOC00YzA4LWFjMTQtMjk3ZGQ5MDEzYzNjXC9kZjJuMmhuLWEyYTBjNjg0LTMxMzEtNGRlZS1hNTUzLTVhNWIwODFiYTVmZi5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.M_6SE-zMXIfaR3Ltl5aN8DTMNVYeOGoA89-sfSKwJzo"
            alt="Company template"
          />
        </Grid>
      </Grid>
    </ResponsiveBox>
  );
};
