import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useNetworkStatus } from "../utils/useNetworkStatus";

export default function NoNetworkAlert() {
  const [showOfflineAlert, setShowOfflineAlert] = useState(false);
  const isOnline = useNetworkStatus();

  useEffect(() => {
    if (isOnline === true) {
      setShowOfflineAlert(false);
    } else {
      setShowOfflineAlert(true);
    }
  }, [isOnline]);

  return (
    <Box sx={{ width: "100%", marginTop: "-5px" }}>
      <Collapse in={showOfflineAlert}>
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowOfflineAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          No network connection
        </Alert>
      </Collapse>
    </Box>
  );
}
