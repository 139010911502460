import { Edit, SimpleForm, TextInput } from "react-admin";

export function UsersEdit() {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="first_name" />
        <TextInput source="last_name" />
      </SimpleForm>
    </Edit>
  );
}
