import {
  Datagrid,
  List,
  SearchInput,
  SimpleList,
  TextField,
} from "react-admin";
import { useMediaQuery, Theme } from "@mui/material";

export function UsersListMobile() {
  return (
    <List>
      <SimpleList
        primaryText={(record) => `${record.first_name} ${record.last_name}`}
      />
    </List>
  );
}

export function UsersList() {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    return <UsersListMobile />;
  }

  return (
    <List filters={[<SearchInput key={0} source="email@ilike" alwaysOn />]}>
      <Datagrid rowClick="show">
        <TextField source="email" />
        <TextField source="first_name" />
        <TextField source="last_name" />
      </Datagrid>
    </List>
  );
}
