import { Layout, LayoutProps } from "react-admin";
import {
  Theme,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import { useNavigate, useLocation, matchRoutes } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import InventoryIcon from "@mui/icons-material/Inventory";
import { MyAppBar } from "./AppBar";
import { MyMenu } from "./Menu";
import { DialogContextProvider } from "../utils/DialogContext";

function MyBottomNavigation() {
  const routes: Record<number, string> = {
    0: "/inventories",
    1: "/inventories/create",
    2: "/products",
  };
  const location = useLocation();
  const match = matchRoutes(
    [
      { path: "/inventories/*", caseSensitive: false, meta: 0 },
      { path: "/inventories/create", caseSensitive: false, meta: 1 },
      { path: "/products/*", caseSensitive: false, meta: 2 },
    ],
    location.pathname
  );
  const meta = match?.[0]?.route?.meta;
  const navigate = useNavigate();
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={meta}
        onChange={(_event, newValue) => {
          navigate(routes[newValue]);
        }}
      >
        <BottomNavigationAction
          label="Inventories"
          icon={<ChecklistRtlIcon />}
        />
        <BottomNavigationAction label="New Inventory" icon={<AddIcon />} />
        <BottomNavigationAction label="Products" icon={<InventoryIcon />} />
      </BottomNavigation>
    </Paper>
  );
}

export const MyLayout = (props: LayoutProps) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    return (
      <>
        <Layout {...props} menu={MyMenu} appBar={MyAppBar} />
        <MyBottomNavigation />
      </>
    );
  }

  return (
    <DialogContextProvider>
      <Layout {...props} menu={MyMenu} appBar={MyAppBar} />
    </DialogContextProvider>
  );
};
