import { Create, SimpleForm, TextInput, useNotify } from "react-admin";
import { FieldValues } from "react-hook-form";
import { supabaseClient } from "../../providers/supabase";

export function UsersCreate() {
  const notify = useNotify();

  async function createUser(data: FieldValues) {
    const { error } = await supabaseClient.functions.invoke("create_user", {
      body: {
        email: data.email,
        password: data.password,
        tenantId: 10,
      },
    });
    if (error) {
      notify("Error creating user", { type: "error" });
    } else {
      notify("User created", { type: "success" });
    }
  }

  return (
    <Create>
      <SimpleForm onSubmit={createUser}>
        <TextInput source="email" />
        <TextInput source="password" />
        <TextInput source="firstName" />
        <TextInput source="lastName" />
      </SimpleForm>
    </Create>
  );
}
