import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const NotificationShow = () => (
  <Show>
    <SimpleShowLayout>
      <DateField source="created_at" />
      <TextField source="sender" />
      <TextField source="receiver" />
      <TextField source="id" />
      <ReferenceField source="tenant_id" reference="tenants" />
    </SimpleShowLayout>
  </Show>
);
