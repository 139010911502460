import {
  Create,
  DateInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export function ProductsCreate() {
  return (
    <Create>
      <SimpleForm>
        <TextInput label="Id" source="id" />
        <TextInput label="Descrizione" source="description" />
        <TextInput label="Unità di misura" source="um" />
        <NumberInput label="Prezzo" source="cost" />
        <DateInput label="Aggiornato al" source="updated_at" />
      </SimpleForm>
    </Create>
  );
}
