import { Dialog, DialogProps } from "@mui/material";
import { createContext, ReactNode, useContext, useState } from "react";

export type DialogPropsOmitted = Omit<DialogProps, "open" | "onClose">;

interface IDialogContext {
  open: boolean;
  setOpen: (value: boolean) => void;
  content: ReactNode | null;
  setContent: (value: ReactNode | null) => void;
  contentProps?: DialogPropsOmitted;
  setContentProps: (value: DialogPropsOmitted) => void;
  closeOnBackdropClick?: boolean;
  setCloseOnBackdropClick: (value: boolean) => void;
}

export const DialogContext = createContext<IDialogContext>({
  open: false,
  setOpen: () => {
    /* */
  },
  content: null,
  setContent: () => {
    /* */
  },
  contentProps: undefined,
  setContentProps: () => {
    /* */
  },
  closeOnBackdropClick: false,
  setCloseOnBackdropClick: () => {
    /* */
  },
});

export function useDialogContext() {
  const { setOpen, setContent, setContentProps, setCloseOnBackdropClick } =
    useContext(DialogContext);

  function showDialog(
    contentToShow: ReactNode,
    contentProps?: DialogPropsOmitted,
    closeOnBackdropClick?: boolean
  ) {
    setContent(contentToShow);
    setOpen(true);
    if (contentProps) {
      setContentProps(contentProps);
    }
    if (closeOnBackdropClick !== undefined) {
      setCloseOnBackdropClick(closeOnBackdropClick);
    }
  }

  function closeDialog() {
    setContent(null);
    setOpen(false);
  }

  return { showDialog, closeDialog };
}

interface DialogContextProviderProps {
  children: ReactNode;
}

export function DialogContextProvider({
  children,
}: DialogContextProviderProps) {
  const [isGeneralDialogOpen, setGeneralDialogOpen] = useState<boolean>(false);
  const [generalDialogContent, setGeneralDialogContent] =
    useState<ReactNode | null>();
  const [generalDialogContentProps, setGeneralDialogContentProps] =
    useState<DialogPropsOmitted>();
  const [
    isGeneralDialogCloseOnBackdropClick,
    setIsGeneralDialogCloseOnBackdropClick,
  ] = useState<boolean | undefined>(undefined);

  function handleGeneralDialogClosure(
    reason: "backdropClick" | "escapeKeyDown"
  ): void {
    if (
      isGeneralDialogCloseOnBackdropClick !== undefined &&
      reason === "backdropClick"
    ) {
      if (isGeneralDialogCloseOnBackdropClick === true) {
        setGeneralDialogOpen(false);
        setIsGeneralDialogCloseOnBackdropClick(undefined);
      }
    } else {
      setGeneralDialogOpen(false);
      setIsGeneralDialogCloseOnBackdropClick(undefined);
    }
  }

  return (
    <DialogContext.Provider
      value={{
        open: isGeneralDialogOpen,
        setOpen: setGeneralDialogOpen,
        content: generalDialogContent,
        setContent: setGeneralDialogContent,
        contentProps: generalDialogContentProps,
        setContentProps: setGeneralDialogContentProps,
        closeOnBackdropClick: isGeneralDialogCloseOnBackdropClick,
        setCloseOnBackdropClick: setIsGeneralDialogCloseOnBackdropClick,
      }}
    >
      {children}
      <Dialog
        open={isGeneralDialogOpen}
        onClose={(_: Event, reason: "backdropClick" | "escapeKeyDown") =>
          handleGeneralDialogClosure(reason)
        }
        {...generalDialogContentProps}
      >
        {generalDialogContent}
      </Dialog>
    </DialogContext.Provider>
  );
}
