import { BrowserRouter, Route } from "react-router-dom";
import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  ShowGuesser,
  CustomRoutes,
} from "react-admin";
import { QueryClient } from "react-query";
import { MyLayout } from "./layout/MyLayout";
import { Dashboard } from "./Dashboard";
import { i18nProvider } from "./locales/i18nProvider";
import { dataProvider } from "./providers/DataProvider";
import { authProvider } from "./providers/AuthProvider";
import { InventoriesEdit } from "./modules/inventories/InventoriesEdit";
import { InventoriesList } from "./modules/inventories/list/InventoriesList";
import { InventoriesCreate } from "./modules/inventories/InventoriesCreate";
import { LoginPage } from "./layout/LoginPage";
import { ProductsList } from "./modules/products/ProductsList";
import { ProductsEdit } from "./modules/products/ProductsEdit";
import { ProductsCreate } from "./modules/products/ProductsCreate";
import { UsersList } from "./modules/users/UsersList";
import { UsersShow } from "./modules/users/UsersShow";
import { UsersEdit } from "./modules/users/UsersEdit";
import { UsersCreate } from "./modules/users/UsersCreate";
import { MyTheme } from "./layout/Theme";
import { NotificationList } from "./modules/notifications/NotificationsList";
import { NotificationCreate } from "./modules/notifications/NotificationsCreate";
import { NotificationShow } from "./modules/notifications/NotificationsShow";
import { NotificationEdit } from "./modules/notifications/NotificationsEdit";
import { ForgotPasswordPage } from "./layout/ForgotPasswordPage";
import { SetPasswordPage } from "./layout/SetPasswordPage";
import { InventoriesExportShow } from "./modules/export/InventoriesExportShow";
import { InventoriesExportList } from "./modules/export/InventoriesExportList";
import { InventoriesExportCreate } from "./modules/export/InventoriesExportCreate";
import { ProductImportsList } from "./modules/imports/ProductImportsList";
import { InventoryImportsList } from "./modules/imports/InventoryImportsList";
import { WarehouseList } from "./modules/warehouse/WarehouseList";
import { WarehouseShow } from "./modules/warehouse/WarehouseShow";
import { WarehouseEdit } from "./modules/warehouse/WarehouseEdit";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retryDelay: 10000,
    },
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <Admin
        disableTelemetry
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={MyLayout}
        i18nProvider={i18nProvider}
        loginPage={LoginPage}
        theme={MyTheme}
        queryClient={queryClient}
      >
        <CustomRoutes noLayout>
          <Route path={"/forgot-password"} element={<ForgotPasswordPage />} />
          <Route path={"/set-password"} element={<SetPasswordPage />} />
        </CustomRoutes>
        <Resource
          name="inventories"
          list={InventoriesList}
          edit={InventoriesEdit}
          create={InventoriesCreate}
        />
        <Resource
          name="products"
          list={ProductsList}
          edit={ProductsEdit}
          create={ProductsCreate}
        />
        <Resource
          name="imports"
          list={ListGuesser}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource
          name="product_imports"
          list={ProductImportsList}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource
          name="inventory_imports"
          list={InventoryImportsList}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource
          name="exports"
          list={ListGuesser}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource
          name="inventories_export"
          list={InventoriesExportList}
          show={InventoriesExportShow}
          create={InventoriesExportCreate}
        />
        <Resource
          name="import_rules"
          list={ListGuesser}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource
          name="export_rules"
          list={ListGuesser}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource
          name="notifications"
          list={NotificationList}
          show={NotificationShow}
          edit={NotificationEdit}
          create={NotificationCreate}
        />
        <Resource
          name="warehouses"
          list={WarehouseList}
          show={WarehouseShow}
          edit={WarehouseEdit}
        />
        <Resource
          name="profiles_full"
          list={UsersList}
          show={UsersShow}
          edit={UsersEdit}
          create={UsersCreate}
        />
        <Resource name="product_quantities" />
      </Admin>
    </BrowserRouter>
  );
};

export default App;
