import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";
import { CustomChipField } from "./WarehouseList";

export function WarehouseShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField label="Nome" source="name" />
        <DateField label="Creato il" source="created_at" showTime />
        <CustomChipField />
      </SimpleShowLayout>
    </Show>
  );
}
