import {
  Datagrid,
  FilterLiveSearch,
  List,
  TextField,
  SavedQueriesList,
  DateField,
  useRecordContext,
  FilterList,
  FilterListItem,
  useGetList,
} from "react-admin";
import {
  useMediaQuery,
  Theme,
  Card,
  CardContent,
  Tooltip,
  Avatar,
  Chip,
} from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TimerIcon from "@mui/icons-material/Timer";
import LabelIcon from "@mui/icons-material/Label";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { useEffect, useState } from "react";
import { ProductsListMobile } from "./ProductsListMobile";
import { ILabels } from "../../types/derived_types";
import { supabaseClient } from "../../providers/supabase";

export const ProductsFilterSidebar = () => {
  // Get labels from database
  const { data: labels, isLoading, error } = useGetList("labels", {});

  return (
    <Card sx={{ order: -1, mr: 2, mt: 0, width: 300 }}>
      <CardContent>
        <SavedQueriesList />
        <FilterList label="Last inventory date" icon={<TimerIcon />}>
          <FilterListItem
            label="One month"
            value={{
              "last_inventory_date@lte": new Date(
                new Date().setMonth(new Date().getMonth() - 1)
              ).toISOString(),
            }}
          />
          <FilterListItem
            label="Two months"
            value={{
              "last_inventory_date@lte": new Date(
                new Date().setMonth(new Date().getMonth() - 2)
              ).toISOString(),
            }}
          />
          <FilterListItem
            label="Three months"
            value={{
              "last_inventory_date@lte": new Date(
                new Date().setMonth(new Date().getMonth() - 3)
              ).toISOString(),
            }}
          />
          <FilterListItem
            label="Six months"
            value={{
              "last_inventory_date@lte": new Date(
                new Date().setMonth(new Date().getMonth() - 6)
              ).toISOString(),
            }}
          />
          <FilterListItem
            label="One year"
            value={{
              "last_inventory_date@lte": new Date(
                new Date().setMonth(new Date().getMonth() - 12)
              ).toISOString(),
            }}
          />
        </FilterList>
        <FilterList label="Label" icon={<LabelIcon />}>
          {!error &&
            !isLoading &&
            labels &&
            labels?.map((label: ILabels) => {
              return (
                <FilterListItem
                  sx={{
                    "& .MuiTypography-root": {
                      backgroundColor: label.color,
                      borderRadius: "20px",
                      padding: "2px",
                      paddingLeft: "10px",
                      color: "#3c3c3c",
                      fontWeight: "600 !important",
                    },
                  }}
                  key={label.id}
                  label={label.title}
                  value={{
                    "labels.id": label.id,
                  }}
                />
              );
            })}
        </FilterList>
      </CardContent>
    </Card>
  );
};

const BadgesField = () => {
  const { barcodes } = useRecordContext();
  if (!barcodes || barcodes.length === 0) {
    return null;
  }
  return (
    <Avatar sx={{ height: "25px", width: "25px" }}>
      <Tooltip title={barcodes.join(",")}>
        <QrCodeIcon fontSize="small" />
      </Tooltip>
    </Avatar>
  );
};

export function ProductField() {
  const product = useRecordContext();
  const [firstProductLabelsId, setFirstProductLabelsId] = useState<string[]>(
    []
  );
  const [moreProductLabelsId, setMoreProductLabelsId] = useState<string[]>([]);
  const { data: allLabels } = useGetList<ILabels>("labels");

  useEffect(() => {
    supabaseClient
      .from("products_label")
      .select("id, label_id")
      .filter("product_id", "eq", product.id)
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        }
        if (data && data.length > 0) {
          const temp = [];
          const innerTemp = [];
          for (let idx = 0; idx < data.length; idx++) {
            if (idx <= 2) {
              temp.push(data[idx].label_id);
            } else {
              innerTemp.push(data[idx].label_id);
            }
          }
          setFirstProductLabelsId(temp);
          setMoreProductLabelsId(innerTemp);
        }
      });
  }, [product.id]);

  const getSelectValues = (source: string[]): string[] => {
    let result: string[] = [];
    if (allLabels !== null && allLabels !== undefined) {
      result = allLabels
        .filter((e) => source.includes(e.id) === true)
        .map((el) => el.title);
    }
    return result;
  };

  const getColorByLabelTitle = (title: string): string => {
    let result = "#ffffff";

    if (allLabels !== null && allLabels !== undefined) {
      const found = allLabels.find((e) => e.title === title);
      if (found) {
        result = found.color;
      }
    }

    return result;
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "flex-start",
      }}
    >
      {allLabels !== null && allLabels !== undefined && allLabels.length > 0 ? (
        <span>
          {getSelectValues(firstProductLabelsId).map(
            (value: string, idx: number) => (
              <Chip
                key={idx}
                label={value}
                sx={{
                  backgroundColor: getColorByLabelTitle(value),
                }}
              />
            )
          )}
        </span>
      ) : null}
      {moreProductLabelsId.length > 0 ? (
        <Avatar sx={{ height: "25px", width: "25px", marginLeft: "0.5rem" }}>
          <Tooltip title={getSelectValues(moreProductLabelsId).join()}>
            <ColorLensIcon fontSize="small" />
          </Tooltip>
        </Avatar>
      ) : null}
    </div>
  );
}

export function ProductsList() {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    return <ProductsListMobile />;
  }

  const filters = [
    <FilterLiveSearch key={1} source="description@ilike" alwaysOn />,
  ];

  return (
    <List
      resource="products_full"
      aside={<ProductsFilterSidebar />}
      filters={filters}
    >
      <Datagrid rowClick={(record) => `/products/${record}`}>
        <TextField label="Id" source="id" />
        <TextField label="Descrizione" source="description" />
        <ProductField />
        <BadgesField />
        <DateField
          label="Last inventory date"
          source="last_inventory_date"
          showTime
        />
        <TextField
          sortable={false}
          label={
            <Tooltip title="Unità di misura">
              <span>U.M.</span>
            </Tooltip>
          }
          source="um"
        />
        <TextField label="Costo" source="cost" />
      </Datagrid>
    </List>
  );
}
