import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "lodash";
import { PostgrestError } from "@supabase/postgrest-js";
import { supabaseClient } from "../../providers/supabase";
import { IProduct } from "../../types/derived_types";

interface AutocompleteProductProps {
  value: string | null;
  setProduct: (product: IProduct) => void;
}

export default function AutocompleteProduct({
  value,
  setProduct,
}: AutocompleteProductProps) {
  const [open, setOpen] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState<string>(value || "");

  const [options, setOptions] = React.useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<PostgrestError | null>(null);

  React.useEffect(() => {
    // debunce the following function
    const debouncedSearch = debounce((searchValue: string) => {
      const fetchProducts = async () => {
        setIsLoading(true);
        const { data, error } = await supabaseClient
          .from("products")
          .select("*")
          .limit(25)
          .filter(
            "description",
            "ilike",
            `*${searchValue.trim().replaceAll(/\s/g, "*")}*`
          );
        if (error) {
          setError(error);
        } else {
          setOptions(data);
        }
        setIsLoading(false);
      };
      fetchProducts();
    }, 200);

    debouncedSearch(searchValue);
  }, [searchValue]);

  if (error || options === undefined) {
    return <div>Error...</div>;
  }

  return (
    <Autocomplete
      id="asynchronous-demo"
      clearOnEscape
      autoSelect
      fullWidth
      freeSolo
      open={open}
      autoHighlight={searchValue.length > 0}
      value={{ id: "", description: searchValue || "" } as IProduct}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_event, value) => {
        if (value !== null && typeof value !== "string") {
          setProduct(value);
        }
      }}
      filterOptions={(x) => x}
      onInputChange={(_, value) => {
        setSearchValue(value);
      }}
      isOptionEqualToValue={(option, value) => {
        return (
          option.description?.toLowerCase() === value.description?.toLowerCase()
        );
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description || ""
      }
      options={options}
      loading={isLoading}
      renderInput={(params) => (
        <TextField {...params} autoFocus label={false} />
      )}
    />
  );
}
