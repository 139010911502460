import { defaultTheme } from "react-admin";

export const MyTheme = {
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    h1: {
      fontSize: "2.3rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "1.8rem",
      fontWeight: 500,
      marginTop: "16px",
      marginBottom: "8px",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 500,
      marginTop: "16px",
      marginBottom: "8px",
    },
    h4: {
      fontSize: "1.3rem",
      fontWeight: 500,
      marginTop: "16px",
      marginBottom: "8px",
    },
    h5: {
      // used for card titles
      fontSize: "1.2rem",
      fontWeight: 550,
    },
  },
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#1a237e",
    },
    secondary: {
      main: "#ff1744",
    },
  },
  components: {
    ...defaultTheme.components,
    RaLayout: {
      styleOverrides: {
        root: {
          marginTop: "16px",
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          marginTop: "-16px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined" as const,
        margin: "dense" as const,
        size: "small" as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined" as const,
        margin: "dense" as const,
        size: "small" as const,
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingTop: "7px",
          paddingBottom: "4px",
          borderBottom: "1px solid #ababab",
          borderRadius: "3px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "& .RaTabbedShowLayout-content": {
            padding: "0px !important",
          },
        },
      },
    },
  },
};
