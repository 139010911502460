import { useDataProvider, useNotify } from "react-admin";
import { FileDragAndDrop, pageType } from "./FileDragAndDrop";

export function ProductImportsList() {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const importRow = (
    currentPage: pageType,
    mappedCSV: any | null,
    parsedCSV: any[] | null
  ) => {
    switch (currentPage) {
      case pageType.PRODUCTS:
        {
          const temp: any = { ...mappedCSV };
          const reqPayload: any = {};

          if (parsedCSV !== null && temp !== null) {
            for (const data of parsedCSV) {
              Object.entries(temp).forEach((value: [string, any]) => {
                if (Object.prototype.hasOwnProperty.call(data, value[1])) {
                  reqPayload[`${value[0]}`] = data[`${value[1]}`];
                }
              });

              dataProvider
                .create("products", { data: reqPayload })
                .then(() => {
                  notify("Prodotti importati con successo", {
                    type: "success",
                  });
                })
                .catch((reason: any) => {
                  notify(`${reason}`, { type: "error" });
                });
            }
          }
        }

        break;
      default:
        console.error("WRONG PAGE TYPE!");
        break;
    }
  };

  const csvColumnToMap: any[] = [
    { name: "id" },
    { name: "description" },
    { name: "um" },
    { name: "cost" },
  ];

  return (
    <FileDragAndDrop
      csvColumnToMap={csvColumnToMap}
      currentPage={pageType.PRODUCTS}
      importRow={importRow}
    />
  );
}
