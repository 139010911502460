import { Create, ReferenceInput, SelectInput, SimpleForm } from "react-admin";

export function NotificationCreate() {
  return (
    <Create>
      <SimpleForm>
        <ReferenceInput source="sender" reference="profiles_full">
          <SelectInput optionText="first_name" />
        </ReferenceInput>
        <ReferenceInput source="receiver" reference="profiles_full">
          <SelectInput optionText="first_name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
