import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  Datagrid,
  TextField,
  ListBase,
  ReferenceField,
  DateField,
  Button,
  FunctionField,
  TabbedShowLayout,
  Show,
  SimpleForm,
  EditBase,
  CreateBase,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  NumberInput,
  useRefresh,
  Loading,
  useGetList,
  useNotify,
  useRedirect,
  useDataProvider,
} from "react-admin";
import { FieldValues } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import { useEffect, useState } from "react";
import { useDialogContext } from "../../utils/DialogContext";
import { ILabels, IProductQuantities } from "../../types/derived_types";
import { supabaseClient } from "../../providers/supabase";

interface EditProductQuantityLocationButtonProps {
  id: string;
}

function EditProductQuantityLocationButton({
  id,
}: EditProductQuantityLocationButtonProps) {
  const product = useRecordContext();
  const refresh = useRefresh();
  const { showDialog, closeDialog } = useDialogContext();

  function onSuccess() {
    closeDialog();
    refresh();
  }

  function handleClick() {
    showDialog(
      <EditBase<IProductQuantities>
        resource="product_quantities"
        id={id}
        mutationOptions={{ onSuccess }}
      >
        <SimpleForm defaultValues={{ id: product.id, movement_type: "manual" }}>
          <ReferenceInput source="warehouse_id" reference="warehouses">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="warehouse_slot" />
          <NumberInput source="quantity" />
        </SimpleForm>
      </EditBase>
    );
  }

  return <Button label="Edit" startIcon={<SyncIcon />} onClick={handleClick} />;
}

function AddProductQuantityLocationButton() {
  const product = useRecordContext();
  const refresh = useRefresh();
  const { showDialog, closeDialog } = useDialogContext();

  function onSuccess() {
    closeDialog();
    refresh();
  }

  function handleClick() {
    showDialog(
      <CreateBase<IProductQuantities>
        resource="product_quantities"
        mutationOptions={{ onSuccess }}
      >
        <SimpleForm defaultValues={{ id: product.id, movement_type: "manual" }}>
          <ReferenceInput source="warehouse_id" reference="warehouses">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="warehouse_slot" />
          <NumberInput source="quantity" />
        </SimpleForm>
      </CreateBase>
    );
  }

  return <Button label="Add" startIcon={<AddIcon />} onClick={handleClick} />;
}

interface DeleteQuantityLocationButtonProps {
  id: string;
}

function DeleteQuantityLocationButton({
  id,
}: DeleteQuantityLocationButtonProps) {
  const { showDialog, closeDialog } = useDialogContext();

  function handleClick() {
    showDialog(
      <>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this location?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={closeDialog} />
          <Button
            variant="contained"
            label="Delete"
            onClick={() => {
              // TODO finish
              console.debug(id);
            }}
          />
        </DialogActions>
      </>
    );
  }

  return (
    <MuiButton size="small" onClick={handleClick}>
      <DeleteIcon fontSize="small" />
    </MuiButton>
  );
}

function ProductQuantities() {
  return (
    <ListBase resource="product_quantities">
      <Datagrid sx={{ width: "100%" }} bulkActionButtons={false}>
        <ReferenceField source="warehouse_id" reference="warehouses">
          <TextField label="Magazzino" source="name" sortable={false} />
        </ReferenceField>

        <TextField label="Slot" source="warehouse_slot" sortable={false} />
        <TextField label="Quantità" source="quantity" sortable={false} />
        <DateField
          label="Last update"
          source="created_at"
          sortable={false}
          showTime
        />
        <FunctionField
          label={<AddProductQuantityLocationButton />}
          render={(record: IProductQuantities) => {
            return (
              <>
                <EditProductQuantityLocationButton id={record.id} />
                <DeleteQuantityLocationButton id={record.id} />
              </>
            );
          }}
        />
      </Datagrid>
    </ListBase>
  );
}

function ProductMovements() {
  return (
    <ListBase resource="product_quantities">
      <Datagrid sx={{ width: "100%" }} bulkActionButtons={false}>
        <DateField label="Date" source="created_at" sortable={false} showTime />
        <ReferenceField source="warehouse_id" reference="warehouses">
          <TextField label="Magazzino" source="name" sortable={false} />
        </ReferenceField>
        <TextField label="Slot" source="warehouse_slot" sortable={false} />
        <TextField source="movement_type" label="Tipo movimento" />
        <TextField label="Quantità" source="quantity" sortable={false} />
      </Datagrid>
    </ListBase>
  );
}

function ProductGeneralInformations() {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const [productLabelsId, setProductLabelsId] = useState<string[]>([]);

  const updateProduct = async (item: any): Promise<boolean> => {
    let wasSuccessfull = true;

    const { error } = await supabaseClient
      .from("products")
      .update({
        id: item.id,
        description: item.description,
        um: item.um,
        cost: item.cost,
        updated_at: item.updated_at,
        barcodes: item.barcodes,
      })
      .eq("id", item.id);
    if (error) {
      notify(`${error}`, { type: "error" });
      wasSuccessfull = false;
    }

    return wasSuccessfull;
  };

  function onSubmit(formValues: FieldValues) {
    const {
      id: input_id,
      title: input_title,
      color: input_color,
      description: input_description,
      um: input_um,
      cost: input_cost,
      updated_at: input_updated_at,
      barcodes: input_barcodes,
    } = formValues;

    const productId = input_id;

    updateProduct({
      id: input_id,
      title: input_title,
      color: input_color,
      description: input_description,
      um: input_um,
      cost: input_cost,
      updated_at: input_updated_at,
      barcodes: input_barcodes,
    }).then((wasSuccessfull: boolean) => {
      if (wasSuccessfull === true) {
        if (productLabelsId.length === 0) {
          onSuccessEdit();
        }
        for (let idx = 0; idx < productLabelsId.length; idx++) {
          dataProvider
            .create("products_label", {
              data: { product_id: productId, label_id: productLabelsId[idx] },
            })
            .then(() => {
              if (idx === productLabelsId.length - 1) {
                onSuccessEdit();
              }
            })
            .catch((reason: any) => {
              notify(`${reason}`, { type: "error" });
            });
        }
      }
    });
  }

  const onSuccessEdit = (): void => {
    notify("Prodotto aggiornato con successo", {
      type: "success",
    });
    redirect("/products");
  };

  return (
    <EditBase>
      <SimpleForm onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              borderRight: "1px solid #ababab",
              paddingRight: "10px",
            }}
          >
            <TextInput label="Id" source="id" disabled fullWidth />
            <TextInput label="Descrizione" source="description" fullWidth />
            <TextInput label="Unità di misura" source="um" fullWidth />
            <TextInput label="Costo" source="cost" fullWidth />
            <LabelsEdit
              onChangeLabelsId={(val: string[]) => {
                setProductLabelsId(val);
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ArrayInput label="Codice a barre" source="barcodes" fullWidth>
              <SimpleFormIterator inline fullWidth>
                <TextInput
                  label="codice"
                  source=""
                  helperText={false}
                  fullWidth
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </EditBase>
  );
}

interface ILabelsEdit {
  onChangeLabelsId: (value: string[]) => void;
}

export default function LabelsEdit({ onChangeLabelsId }: ILabelsEdit) {
  const product = useRecordContext();
  const [productLabelsId, setProductLabelsId] = useState<string[]>([]);
  const [choosenProductLabelsId, setChoosenProductLabelsId] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { data: allLabels } = useGetList<ILabels>("labels");

  useEffect(() => {
    setIsLoading(true);
    supabaseClient
      .from("products_label")
      .select("id, label_id")
      .filter("product_id", "eq", product.id)
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        }
        if (data) {
          setProductLabelsId(data.map((el) => el.label_id));
        }
        setIsLoading(false);
      });
  }, [product.id]);

  if (isLoading) {
    return <Loading />;
  }

  const handleChange = (event: SelectChangeEvent<typeof productLabelsId>) => {
    const {
      target: { value },
    } = event;
    const valueChoosen = value[value.length - 1];

    if (value.length > 0 && productLabelsId.indexOf(valueChoosen) === -1) {
      const t = [...productLabelsId];
      t.push(valueChoosen);
      setProductLabelsId(t);
      const temp = [...choosenProductLabelsId];
      temp.push(valueChoosen);
      setChoosenProductLabelsId(temp);
      onChangeLabelsId(temp);
    }
  };

  const getSelectValues = (): string[] => {
    let result: string[] = [];
    if (allLabels !== null && allLabels !== undefined) {
      result = allLabels
        .filter((e) => productLabelsId.includes(e.id) === true)
        .map((el) => el.title);
    }
    return result;
  };

  const getColorByLabelTitle = (title: string): string => {
    let result = "#ffffff";

    if (allLabels !== null && allLabels !== undefined) {
      const found = allLabels.find((e) => e.title === title);
      if (found) {
        result = found.color;
      }
    }

    return result;
  };

  return (
    <div>
      {allLabels !== null && allLabels !== undefined && allLabels.length > 0 ? (
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel id="demo-multiple-chip-label">Etichetta</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={getSelectValues()}
            input={
              <OutlinedInput id="select-multiple-chip" label="Etichetta" />
            }
            onChange={handleChange}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    sx={{
                      backgroundColor: getColorByLabelTitle(value),
                    }}
                  />
                ))}
              </Box>
            )}
          >
            {allLabels.map((label: ILabels) => (
              <MenuItem key={label.id} value={label.id}>
                <Box
                  sx={{
                    backgroundColor: label.color,
                    width: "100%",
                    fontWeight: 700,
                    textAlign: "center",
                    border: "2px solid #ffffff",
                    borderRadius: "2rem",
                  }}
                >
                  {label.title}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </div>
  );
}

export function ProductsEdit() {
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="General">
          <ProductGeneralInformations />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Quantities" sx={{ padding: 0 }}>
          <ProductQuantities />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Movements">
          <ProductMovements />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
}
