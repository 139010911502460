import {
  Button,
  Edit,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  BooleanInput,
} from "react-admin";
import { Box } from "@mui/material";
import {
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useFormContext, FieldValues } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import InputIcon from "@mui/icons-material/Input";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router";
import AutocompleteProduct from "./AutocompleteProduct";
import DataGridWithSingleClickCellEdit from "../../components/DataGridWithSingleClickCellEdit";
import { IInventory, IInventoryRow, IProduct } from "../../types/derived_types";

const GridCustomToolbar = () => {
  const api = useGridApiContext();
  const selectedRows = api.current.getSelectedRows();

  const disableDeleteButton = selectedRows.size <= 0;
  function deleteSelectedRows() {
    const selectedRowsIds = selectedRows.keys();
    for (const id of selectedRowsIds) {
      api.current.updateRows([{ id, _action: "delete" }]);
    }
  }

  function openImportSidebar() {
    //setOpenImportSidebar(true);
  }

  function handleAddRow() {
    const newId = nanoid();
    api.current.updateRows([{ id: newId }]);
    api.current.startCellEditMode({ id: newId, field: "code" });
  }

  return (
    <GridToolbarContainer>
      <Button label="Add row" onClick={handleAddRow} startIcon={<AddIcon />} />
      <Button
        label="Import"
        onClick={openImportSidebar}
        startIcon={<InputIcon />}
      />
      <Button
        label="Delete Row"
        startIcon={<DeleteIcon />}
        onClick={deleteSelectedRows}
        disabled={disableDeleteButton}
      />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
};

export function InventoryRows() {
  const [data, setData] = useState<Array<IInventoryRow>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const dataProvider = useDataProvider();
  const form = useFormContext();

  useEffect(() => {
    const getInventoryRows = async () => {
      setIsLoading(true);

      try {
        const { data } = await dataProvider.getList("inventory_rows", {
          pagination: { page: 1, perPage: 5 },
          sort: { field: "id", order: "ASC" },
          filter: {},
        });

        setData(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };

    getInventoryRows();
  }, [dataProvider]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !data) {
    return <div>Error</div>;
  }

  const columns: GridColDef<IInventoryRow>[] = [
    {
      field: "code",
      headerName: "Code",
      minWidth: 150,
      editable: true,
      sortable: false,
    },
    {
      field: "description",
      headerName: "Product Name",
      minWidth: 250,
      flex: 1,
      editable: true,
      sortable: false,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const row: IInventoryRow = params.row as IInventoryRow;

        const setProduct = (product: IProduct) => {
          if (product) {
            params.api.setEditCellValue({
              id: params.id,
              field: "description",
              value: product.description,
            });
            params.api.updateRows([
              {
                id: params.id,
                code: product.id,
                description: product.description,
                um: product.um,
                cost: product.cost,
              },
            ]);
          }
        };
        return (
          <AutocompleteProduct
            value={row["description"] || ""}
            setProduct={setProduct}
          />
        );
      },
    },
    {
      field: "um",
      headerName: "U.M.",
      minWidth: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "remote_quantity",
      headerName: "Virtual Quantity",
      minWidth: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "quantity",
      headerName: "Phisical Quantity",
      minWidth: 150,
      editable: true,
      sortable: false,
    },
    {
      field: "difference",
      headerName: "Difference",
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const row: IInventoryRow = params.row as IInventoryRow;
        if (!row.quantity || !row.remote_quantity) {
          return null;
        }
        return row.quantity - row.remote_quantity;
      },
    },
    {
      field: "quantity_updated_at",
      headerName: "Last Update",
      minWidth: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "cost",
      headerName: "Unit cost",
      minWidth: 100,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        const row: IInventoryRow = params.row as IInventoryRow;
        if (!row.cost) {
          return "€ 0.00";
        }
        return `€ ${row.cost.toFixed(2)}`;
      },
    },
    {
      field: "total_cost",
      headerName: "Total cost",
      minWidth: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        const row: IInventoryRow = params.row as IInventoryRow;
        if (!row.quantity || !row.remote_quantity || !row.cost) {
          return "€ 0.00";
        }
        const difference = (row.quantity - row.remote_quantity) * row.cost;
        return `€ ${difference.toFixed(2)}`;
      },
    },
  ];

  const handleOnStateChange: GridEventListener<"stateChange"> = (state) => {
    form.setValue("rows", Object.values(state.rows.dataRowIdToModelLookup), {
      shouldDirty: true,
    });
  };

  const handleProcessRowUpdate = async (
    newRow: IInventoryRow,
    oldRow: IInventoryRow
  ) => {
    if (newRow.code !== oldRow.code) {
      try {
        // We introduced a new code, let's set the product
        const { data: product } = await dataProvider.getOne<IInventoryRow>(
          "products",
          {
            id: newRow.code,
          }
        );
        return {
          id: newRow.id,
          code: newRow.code,
          description: product.description,
          um: product.um,
          cost: product.cost,
          // TODO get quantity
          remote_quantity: 12, //!! TODO do not hardcode
          quantity: null,
        } as IInventoryRow;
      } catch (e) {
        return oldRow;
      }
    }
    return newRow;
  };

  return (
    <DataGridWithSingleClickCellEdit
      slots={{
        toolbar: GridCustomToolbar,
      }}
      sx={{ width: "100%" }}
      autoHeight
      disableColumnMenu
      density="compact"
      rows={data}
      onStateChange={handleOnStateChange}
      processRowUpdate={handleProcessRowUpdate}
      columns={columns}
      pageSizeOptions={[5, 10, 100]}
      checkboxSelection
      disableRowSelectionOnClick
    />
  );
}

export function InventoriesEdit() {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();

  async function onSubmit(formValues: FieldValues) {
    const inventory_id = formValues.id;
    const rows: IInventoryRow[] = formValues.rows;
    const rowsToSave = new Set(rows.map((row) => row.id));

    try {
      const { data } = await dataProvider.getList("inventory_rows", {
        pagination: { page: 1, perPage: 100 },
        filter: { inventory_id },
        sort: { field: "id", order: "ASC" },
      });

      const rowsToDelete = data
        .map((row: IInventoryRow) => row.id)
        .filter(
          (id) =>
            rowsToSave.has(id) === false && id !== undefined && id !== null
        );

      // This whole function should be in a transaction in the backend

      await dataProvider.update<IInventory>("inventories", {
        id: inventory_id,
        data: {
          title: formValues.title,
          description: formValues.description,
          assigned_to: formValues.assigned_to,
          expire_date: formValues.expire_date,
          completion_date: formValues.completion_date,
          draft: formValues.draft,
        },
        previousData: {},
      });

      if (rowsToDelete.length > 0) {
        await dataProvider.deleteMany("inventory_rows", {
          ids: Array.from(rowsToDelete),
        });
      }

      if (formValues.rows.length > 0) {
        await dataProvider.createMany("inventory_rows", {
          ids: formValues.rows.map((row: IInventoryRow) => row.id),
          data: formValues.rows,
        });
      }

      navigate(`/inventories`);
    } catch (e) {
      console.error(e);
      notify("Error: could not update inventory", { type: "error" });
    }
  }

  return (
    <Edit>
      <SimpleForm onSubmit={onSubmit} defaultValues={{ rows: [] }}>
        <Box>
          <TextInput source="name" />
          <ReferenceInput source="assigned_to" reference="profiles_full">
            <SelectInput optionText="first_name" sx={{ mt: 0 }} />
          </ReferenceInput>
          <DateTimeInput source="expire_date" />
          <DateTimeInput source="completion_date" />
          <Button label="Download" startIcon={<DownloadIcon />} />
          <Button label="Export" startIcon={<UploadFileIcon />} />
          <BooleanInput source="draft" />
        </Box>
        <Box sx={{ width: "100%" }}>
          <TextInput
            source="description"
            label="Notes"
            fullWidth
            multiline
            minRows={2}
          />
        </Box>
        <InventoryRows />
      </SimpleForm>
    </Edit>
  );
}
