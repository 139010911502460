import {
  Datagrid,
  DateField,
  FilterLiveSearch,
  List,
  RaRecord,
  ReferenceField,
  SimpleList,
  TextField,
} from "react-admin";
import { InfiniteList } from "ra-ui-materialui";
import { useMediaQuery, Theme, Avatar } from "@mui/material";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import RuleIcon from "@mui/icons-material/Rule";
import { InventoriesFilterSidebar } from "./Sidebar";

export function InventoriesListMobile() {
  const smallFilters = [
    <FilterLiveSearch key={1} source="name@ilike" alwaysOn fullWidth />,
  ];

  const getAvatarBackgroundColor = (record: RaRecord) => {
    if (record.completion_date) {
      return "green";
    }
    if (record.expire_date < new Date().toISOString()) {
      return "red";
    }
    return "primary.main";
  };

  function getAvatarIcon(record: RaRecord) {
    if (!record.completion_date) {
      return <RuleIcon />;
    }
    return <ChecklistRtlIcon />;
  }

  return (
    <InfiniteList
      resource="inventories"
      exporter={false}
      filters={smallFilters}
      actions={false}
      sx={{
        "& form": { width: "100%" },
        "& .RaFilterForm-filterFormInput": { width: "100%" },
      }}
    >
      <SimpleList
        leftAvatar={(record) => (
          <Avatar sx={{ bgcolor: getAvatarBackgroundColor(record) }}>
            {getAvatarIcon(record)}
          </Avatar>
        )}
        primaryText={(record) => record.name}
        secondaryText={(record) =>
          `Due: ${new Date(record.expire_date).toLocaleString()}`
        }
      />
    </InfiniteList>
  );
}

export function InventoriesList() {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (isSmall) {
    return <InventoriesListMobile />;
  }

  const filters = [<FilterLiveSearch key={1} source="name" alwaysOn />];

  return (
    <List aside={<InventoriesFilterSidebar />} filters={filters}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <ReferenceField source="created_by" reference="profiles_full">
          <TextField source="first_name" />
        </ReferenceField>
        <DateField source="created_at" showTime />
        <ReferenceField source="assigned_to" reference="profiles_full">
          <TextField source="first_name" />
        </ReferenceField>
        <DateField source="ready_at" showTime />
        <DateField source="expire_date" showTime />
        <DateField source="completion_date" showTime />
        <TextField source="status" sortable={false} />
      </Datagrid>
    </List>
  );
}
