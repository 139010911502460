import {
  AppBar,
  IconButton,
  Popover,
  Toolbar,
  Typography,
  Link,
} from "@mui/material";
import {
  ListBase,
  SidebarToggleButton,
  SimpleList,
  UserMenu,
} from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
import { styled } from "@mui/system";
import NotificationIcon from "@mui/icons-material/Notifications";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import NoNetworkAlert from "./NoNetworkAlert";
import { DatabaseSync } from "./DatabaseSync";

const Spacer = styled("span")({ flex: 1 });
const Title = styled(Typography)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

function NotificationButton() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const ref = useRef(null);

  function handleOpen() {
    setOpen(true);
  }

  function navigateToNotifications() {
    setOpen(false);
    navigate("/notifications");
  }

  return (
    <>
      <div ref={ref}>
        <IconButton style={{ color: "#ffffff" }} onClick={handleOpen}>
          <NotificationIcon />
        </IconButton>
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Link onClick={navigateToNotifications}>Notifications</Link>
        <ListBase
          resource="notifications"
          filterDefaultValues={{ "isReaded@not.is": null }}
        >
          <SimpleList
            sx={{
              "& .MuiListItemButton-root": {
                py: 0,
                px: "10px",
                borderTop: "1px solid #ededed",
              },
              "& .MuiListItemIcon-root": {
                minWidth: "30px",
              },
            }}
            primaryText={(record) => record.primary}
            leftIcon={() => <NotificationIcon />}
          />
        </ListBase>
      </Popover>
    </>
  );
}

export function MyAppBar() {
  return (
    <>
      <AppBar position={"fixed"}>
        <Toolbar variant={"dense"}>
          <SidebarToggleButton />
          <Spacer />
          <Title
            variant="h6"
            sx={{ display: { xs: "none", sm: "block" } }}
            id="react-admin-title"
          />
          <Spacer />
          <DatabaseSync />
          <NotificationButton />
          <UserMenu />
        </Toolbar>
      </AppBar>
      <NoNetworkAlert />
      <ReactQueryDevtools />
    </>
  );
}
