import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";

export const NotificationList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <DateField showTime source="created_at" />
        <ReferenceField source="sender" reference="profiles_full">
          <TextField source="first_name" />
        </ReferenceField>
        <ReferenceField source="receiver" reference="profiles_full">
          <TextField source="first_name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
