import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Theme,
  Button,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useSetPassword, useSupabaseAccessToken } from "ra-supabase";
import { ReactNode } from "react";
import {
  Form,
  PasswordInput,
  ValidateForm,
  required,
  useNotify,
} from "react-admin";
import { FieldValues } from "react-hook-form";

const ResponsiveBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ECECEC",
  padding: "5rem",
  [theme.breakpoints.only("xs")]: {
    width: "100%",
    height: "950px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "950px",
  },
  [theme.breakpoints.only("sm")]: {
    width: "100%",
    height: "950px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: "100%",
    height: "1200px",
  },
  [theme.breakpoints.only("md")]: {
    width: "100%",
    height: "1200px",
  },
  [theme.breakpoints.only("lg")]: {
    width: "100%",
    height: "1200px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
    height: "1200px",
  },
}));
const GridItem = styled(Card)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
}));

export interface ISetPasswordPageProps {
  children?: ReactNode;
}

/**
 * A component that renders a form for setting the current user password through Supabase.
 * Can be used for the first login after a user has been invited or to reset the password.
 */
export const SetPasswordForm = () => {
  const access_token = useSupabaseAccessToken();
  const refresh_token = useSupabaseAccessToken({
    parameterName: "refresh_token",
  });
  const notify = useNotify();
  const isPageSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const [setPassword] = useSetPassword({
    onError: (error) => {
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message,
        {
          type: "warning",
          messageArgs: {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        }
      );
    },
    onSuccess: () => {
      notify("Password cambiata con successo", { type: "info" });
    },
  });

  const validate = (values: FieldValues) => {
    if (values.password !== values.confirmPassword) {
      return {
        password: "ra-supabase.validation.password_mismatch",
        confirmPassword: "ra-supabase.validation.password_mismatch",
      };
    }
    return undefined;
  };

  const submit = (values: FieldValues) => {
    return setPassword({
      access_token,
      refresh_token,
      password: values.password,
    });
  };

  return (
    <Form onSubmit={submit} validate={validate as ValidateForm}>
      <div>
        {isPageSmall === true ? (
          <PasswordInput
            variant="standard"
            source="password"
            label="Password"
            autoComplete="new-password"
            validate={required()}
            fullWidth
          />
        ) : (
          <PasswordInput
            variant="standard"
            source="password"
            label="Password"
            autoComplete="new-password"
            validate={required()}
          />
        )}
      </div>
      <div>
        {isPageSmall === true ? (
          <PasswordInput
            variant="standard"
            source="confirmPassword"
            label="Ripeti password"
            validate={required()}
            fullWidth
          />
        ) : (
          <PasswordInput
            variant="standard"
            source="confirmPassword"
            label="Ripeti password"
            validate={required()}
          />
        )}
      </div>
      <div>
        {isPageSmall === true ? (
          <Button variant="contained" type="submit" fullWidth>
            Salva
          </Button>
        ) : (
          <Button variant="contained" type="submit">
            Salva
          </Button>
        )}
      </div>
    </Form>
  );
};

export const SetPasswordPage = (props: ISetPasswordPageProps) => {
  const { children = <SetPasswordForm /> } = props;
  const isPageSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const leftGridItemStyle = (isPageSmall: boolean): any => {
    return isPageSmall === true
      ? { height: "100%", borderRadius: "2rem" }
      : {
          height: "100%",
          borderTopLeftRadius: "2rem",
          borderBottomLeftRadius: "2rem",
          backgroundColor: "#ffffff",
        };
  };

  return isPageSmall === true ? (
    <Box sx={{ padding: "2rem" }}>
      <CardHeader
        avatar={
          <Avatar
            alt="company logo"
            src="/icon-512x512.png"
            sx={{ width: 50, height: 50 }}
          />
        }
        title={
          <Typography
            sx={{ fontSize: 18, fontWeight: 600, textAlign: "center" }}
            color="text.primary"
            gutterBottom
          >
            EasyInventory
          </Typography>
        }
      ></CardHeader>
      <Typography
        sx={{ fontSize: 18, fontWeight: 600, textAlign: "center" }}
        color="text.primary"
        gutterBottom
      >
        Imposta password
      </Typography>
      {children}
      <br />
    </Box>
  ) : (
    <ResponsiveBox>
      <Grid
        sx={{ height: "100%", borderRadius: "2rem" }}
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{ height: "100%" }}>
          <GridItem sx={{ ...leftGridItemStyle(isPageSmall) }}>
            <CardHeader
              avatar={
                <Avatar
                  alt="company logo"
                  src="/icon-512x512.png"
                  sx={{ width: 50, height: 50 }}
                />
              }
              title={
                <Typography
                  sx={{ fontSize: 18, fontWeight: 600 }}
                  color="text.primary"
                  gutterBottom
                >
                  EasyInventory
                </Typography>
              }
            ></CardHeader>
            <CardContent sx={{ textAlign: "center" }}>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.primary"
                gutterBottom
              >
                Imposta password
              </Typography>
              {children}
              <br />
            </CardContent>
          </GridItem>
        </Grid>
        <Grid item md={5} lg={5} xl={5} sx={{ height: "100%" }}>
          <CardMedia
            sx={{
              borderTopRightRadius: "2rem",
              borderBottomRightRadius: "2rem",
            }}
            component="img"
            height="100%"
            image="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/f64ff684-b2f8-4c08-ac14-297dd9013c3c/df2n2hn-a2a0c684-3131-4dee-a553-5a5b081ba5ff.png/v1/fill/w_1280,h_800,q_80,strp/google_colors_by_jesssaysno_df2n2hn-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9ODAwIiwicGF0aCI6IlwvZlwvZjY0ZmY2ODQtYjJmOC00YzA4LWFjMTQtMjk3ZGQ5MDEzYzNjXC9kZjJuMmhuLWEyYTBjNjg0LTMxMzEtNGRlZS1hNTUzLTVhNWIwODFiYTVmZi5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.M_6SE-zMXIfaR3Ltl5aN8DTMNVYeOGoA89-sfSKwJzo"
            alt="Company template"
          />
        </Grid>
      </Grid>
    </ResponsiveBox>
  );
};
